//
//  src/layouts/mobile/home/Home.js
//
import * as React from "react";
import Nav      from "../../../components/nav/Nav"
import Calendly from "../../../components/calendly/Calendly"
import Landing  from "../../../components/landing/Landing"
import Footer   from "../../../components/footer/Footer"
import './Home.css'


//
//  hom
//
export default function Home() {
    return (
        <div id="home">
           <Nav      />
           <Calendly />
           <Landing  />
           <Footer   />
        </div>
    );
}

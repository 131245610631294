//
//  src/components/calendly/Calendly.js
//
import * as React from "react";
import { PopupWidget } from "react-calendly";
import './Calendly.css'


//
//  calendly
//
const Calendly = () => {
    return (
        <>
            <PopupWidget
                url={`https://calendly.com/${process.env.REACT_APP_CALENDLY_USERNAME}`}
                rootElement={document.getElementById("root")}
                text="Book Now"
                textColor="#ffffff"
                color="rgba(255, 119, 0, 0.9)"
            />               
        </>
    );
}

//
//  export
//
export default Calendly;

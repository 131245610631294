//
//  src/components/calendly/Calendly.js
//
import * as React from "react";
import { PopupModal } from "react-calendly";
import './Calendly.css'


//
//  calendly
//
export default class Calendly extends React.Component {

    constructor(props) {
        super(props);
  
        this.state = {
            isOpen: false,
        };
    }
  
    render() {
        return (
            <div>
                <button
                    onClick={() => this.setState({ isOpen: true })}
                >
                    Book Now
                </button>
                <PopupModal
                    url={`https://calendly.com/${process.env.REACT_APP_CALENDLY_USERNAME}`}
                    pageSettings={this.props.pageSettings}
                    utm={this.props.utm}
                    prefill={this.props.prefill}
                    onModalClose={() => this.setState({ isOpen: false })}
                    open={this.state.isOpen}
                    rootElement={document.getElementById("root")}
                />
            </div>
        );
    }
}

//
//  src/layouts/depict/gallery/Gallery.js
//
import React, { Component }  from "react";
import Nav      from "../../../components/nav/Nav"
import Calendly from "../../../components/calendly/Calendly"
import Wall     from "../../../components/wall/Wall"
import Footer   from "../../../components/footer/Footer"


//
//  gallery
//
export default class Gallery extends Component {

    render() {
        return <div id="gallery">
            <Nav      />
            <Calendly />
            <Wall     />
            <Footer   />
        </div>
    }

}

//
//  src/layouts/depict/contact/Contact.js
//
import * as React from "react";
import Nav      from "../../../components/nav/Nav"
import Calendly from "../../../components/calendly/Calendly"
import Enquire  from "../../../components/enquire/Enquire"
import Footer   from "../../../components/footer/Footer"
import './Contact.css'


//
//  contact.
//
export default function Contact() {
    return (
        <div id="contact">
            <Nav     />
            <Calendly />                   
            <Enquire />
            <Footer  />
        </div>
    );
}

//
//  src/components/calendly/Calendly.js
//
import * as React from "react";
import { InlineWidget } from "react-calendly";
import './Calendly.css'


//
//  calendly
//
export default class Calendly extends React.Component {
    render() {
        return (
            <>
                <div className="calendly card palette-b">
                    <div className="card-content white-text">
                        <InlineWidget
                            className="btn"
                            url={`https://calendly.com/${process.env.REACT_APP_CALENDLY_USERNAME}`}
                            rootElement={document.getElementById("root")}
                            text="Click here to schedule!"
                            resize="true"
                            styles={{
                                height: "700px"
                            }}
                            pageSettings={{
                                backgroundColor: '2f3136',
                                hideEventTypeDetails: false,
                                hideLandingPageDetails: false,
                                primaryColor: 'acadaf',
                                textColor: 'acadaf'
                            }}
                        />
                    </div>
                </div>              
            </>
        );
    }
}
